<template>
    <pcg-page :page-settings="page">
        <div v-if="error" class="c-content u-mb-exlg">
            <error-msg :message="error"></error-msg>
        </div>
        <div v-if="shop" class="c-content">
            <div class="eventStore-nav">
                <h1
                    :class="{addRemarks: true}"
                    class="c-title c-title-page"
                >{{ shop.shopName }}</h1>
                <event-remarks-modal
                    v-if="shop.beginnerShopFlg || shop.strongShopFlg || shop.championShopFlg"
                    :shop-data="shop"
                    class="remarksModal"
                ></event-remarks-modal>
                <nav v-if="isOrganizer">
                    <ul>
                        <li><a href="#profile">プロフィール</a></li>
                        <li><a href="#event">イベント一覧</a></li>
                    </ul>
                </nav>
                <nav v-else>
                    <ul>
                        <li><a href="#info">店舗情報</a></li>
                        <li><a href="#access">アクセス</a></li>
                        <li><a href="#event">イベント一覧</a></li>
                    </ul>
                </nav>
            </div>
            <div class="eventStore">
                <div
                    v-if="!isOrganizer"
                    id="info"
                    class="u-display-sp shopImage shopImage-sp"
                >
                    <template v-if="shopImages.length > 1">
                        <carousel
                            :settings="settings"
                            :breakpoints="breakpoints"
                            :wrap-around="true"
                            ref="carouselForSp"
                        >
                            <slide
                                v-for="slide, index in shopImages"
                                :key="`slide-${index}`"
                                class="shopImage-carousel"
                            >
                                <div class="shopImage-carousel-image">
                                    <img :src="slide">
                                </div>
                            </slide>
                            <template #addons>
                                <pagination  v-if="shopImages.length > 1" />
                            </template>
                        </carousel>
                        <div class="image-nav prev-image" v-if="shopImages.length > 1"><button @click="prevImage"></button></div>
                        <div class="image-nav next-image" v-if="shopImages.length > 1"><button @click="nextImage"></button></div>
                    </template>
                    <img
                        v-else-if="shopImages.length === 1"
                        :src="shopImages[0]"
                        class="shopImage-single"
                    >
                </div>
                <div class="info">
                    <div v-if="!isOrganizer" class="shop-info">
                        <h2 class="c-title c-title-h2">店舗情報</h2>
                        <p>〒{{ shop.zip }} {{ shop.pref }} {{ shop.addr }}</p>
                        <p>TEL : {{ shop.tel }}</p>
                        <p>{{ shop.businessHours }}</p>
                        <a
                            :href="shop.url"
                            class="c-btn c-btn-primary-outline btn-shop-detail"
                            target="_blank"
                            v-if="shop.url !== '' && shop.url !== null"
                        >
                            <span>店舗詳細</span>
                        </a>
                        <p class="u-text u-text-kome u-text-small" v-if="shop.url !== '' && shop.url !== null">外部サイトにリンクします。</p>
                    </div>
                    <div v-if="!isOrganizer" class="shop-access">
                        <h2 id="access" class="c-title c-title-h2">アクセス</h2>
                        <p>{{ shop.access }}</p>
                        <div class="map">
                            <iframe
                                v-if="mapUrl"
                                class="image"
                                :src="mapUrl"
                                allowfullscreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                        <div class="map-link">
                            <a :href="getMapUrl(shop.geocoding)" target="_blank" class="c-link">拡大地図を表示</a>
                        </div>
                    </div>
                    <div v-if="isOrganizer" class="organizer-info">
                        <div id="profile" class="content-profile">
                            <h2 class="c-title c-title-h2">プロフィール</h2>
                            <div v-if="shop.shopImg1" class="u-text u-text-center organizer-image-outer">
                                <img v-if="shop.shopImg1" :src="shop.shopImg1">
                            </div>
                        </div>
                        <h3 v-if="shop.orgJiki" class="c-title c-title-h3">ポケモンカードゲームを始めた時期</h3>
                        <p v-if="shop.orgJiki" class="content">{{ shop.orgJiki }}</p>
                        <h3 v-if="shop.orgGym" class="c-title c-title-h3">良く行くポケモンカードジム</h3>
                        <p v-if="shop.orgGym" class="content">{{ shop.orgGym }}</p>
                        <h3 v-if="shop.orgPokemon" class="c-title c-title-h3">好きなポケモンのカードと理由</h3>
                        <p v-if="shop.orgPokemon" class="content">{{ shop.orgPokemon }}</p>
                        <h3 v-if="shop.orgYearEvent" class="c-title c-title-h3">イベントオーガナイザー取得年</h3>
                        <p v-if="shop.orgYearEvent" class="content">{{ shop.orgYearEvent }}</p>
                        <h3 class="c-title c-title-h3" v-if="shop.orgComment">コメント</h3>
                        <p class="content" v-if="shop.orgComment">{{ shop.orgComment }}</p>
                        <div v-if="shop.url" class="content-link">
                            <a :href="shop.url" class="link c-link" target="_blank">このオーガナイザーのサイトを見る</a>
                            <p class="u-text u-text-kome u-text-small">外部サイトにリンクします。</p>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <div
                        v-if="!isOrganizer && shopImages[0]"
                        class="u-display-pc shopImage shopImage-pc"
                    >
                        <template v-if="shopImages.length > 1">
                            <carousel
                                :settings="settings"
                                :breakpoints="breakpoints"
                                :wrap-around="true"
                                ref="carouselForPC"
                            >
                                <slide
                                    v-for="slide, index in shopImages"
                                    :key="`slide-${index}`"
                                    class="shopImage-carousel"
                                >
                                    <div class="shopImage-carousel-image">
                                        <img :src="slide">
                                    </div>
                                </slide>
                                <template #addons>
                                    <pagination  v-if="shopImages.length > 1" />
                                </template>
                            </carousel>
                            <div class="image-nav prev-image" v-if="shopImages.length > 1"><button @click="prevImage"></button></div>
                            <div class="image-nav next-image" v-if="shopImages.length > 1"><button @click="nextImage"></button></div>
                        </template>
                        <img
                            v-else-if="shopImages.length === 1"
                            :src="shopImages[0]"
                            class="shopImage-single"
                        >
                    </div>
                    <h2 id="event" class="c-title c-title-h2">イベント一覧</h2>
                    <div class="u-flex u-flex-space-between u-flex-align-items-center change-month">
                        <p class="change-month-display">
                            <span>{{ displayTargetMonth }}</span>月
                        </p>
                        <div class="buttons" v-if="isFutureDay()">
                            <div class="disabled-month month-pre">{{ displayPrevMonth }}月</div>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.nextMonthText } }">
                                <div class="month month-next">{{ displayNextMonth }}月</div>
                            </router-link>
                        </div>
                        <div class="buttons" v-else-if="isFutureMaxDay()">
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.prevMonthText } }">
                                <div class="month month-pre">{{ displayPrevMonth }}月</div>
                            </router-link>
                            <div class="disabled-month month-next">{{ displayNextMonth }}月</div>
                        </div>
                        <div class="buttons" v-else>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.prevMonthText } }">
                                <div class="month month-pre">{{ displayPrevMonth }}月</div>
                            </router-link>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.nextMonthText } }">
                                <div class="month month-next">{{ displayNextMonth }}月</div>
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-if="event.length"
                        class="EventList"
                    >
                        <search-list-item
                            v-for="singleEvent in event"
                            :key="singleEvent.eventHoldingId"
                            :eventData="singleEvent"
                            :isStore="true"
                            :isOrganizer="isOrganizer"
                        />
                    </div>
                    <error-msg
                        v-else
                        class="u-mt-lg u-mb-lg u-text-center"
                    >
                        <p>イベント情報がありません。</p>
                    </error-msg>
                    <div class="u-flex u-flex-space-between u-flex-align-items-center change-month">
                        <p class="change-month-display">
                            <span>{{ displayTargetMonth }}</span>月
                        </p>
                        <div class="buttons" v-if="isFutureDay()">
                            <div class="disabled-month month-pre">{{ displayPrevMonth }}月</div>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.nextMonthText } }">
                                <div class="month month-next">{{ displayNextMonth }}月</div>
                            </router-link>
                        </div>
                        <div class="buttons" v-else-if="isFutureMaxDay()">
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.prevMonthText } }">
                                <div class="month month-pre">{{ displayPrevMonth }}月</div>
                            </router-link>
                            <div class="disabled-month month-next">{{ displayNextMonth }}月</div>
                        </div>
                        <div class="buttons" v-else>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.prevMonthText } }">
                                <div class="month month-pre">{{ displayPrevMonth }}月</div>
                            </router-link>
                            <router-link :to="{ name: 'EventStoreMonth', params: {id: this.$route.params.id, month: this.nextMonthText } }">
                                <div class="month month-next">{{ displayNextMonth }}月</div>
                            </router-link>
                        </div>
                    </div>
                    <ul class="warning u-text-small" v-if="isOrganizer">

                        <li class="u-text-kome warning-margin">公認自主イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome warning-margin">天気などの都合により、イベント内容が予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome warning-margin">定員になり次第、受付を終了させていただく場合がございます。</li>
                        <li class="u-text-kome warning-margin">プレゼントなどは、無くなり次第終了となりますので予めご了承ください。</li>
                    </ul>
                    <ul class="warning u-text-small" v-else>
                        <li class="u-text-kome warning-margin">本イベントは、株式会社ポケモンが主催するイベントではございません。</li>
                        <li class="u-text-kome warning-margin">天気などの都合により、イベント内容が予告なく変更・中止となる場合がございます。</li>
                        <li class="u-text-kome warning-margin">定員になり次第、受付を終了させていただく場合がございます。</li>
                        <li class="u-text-kome warning-margin">プレゼントなどは、イベントやお店により異なります。また、無くなり次第終了となりますので予めご了承ください。</li>
                        <li class="u-text-kome warning-margin">本イベントのレギュレーションや参加条件は、お店によって異なります。</li>
                    </ul>
                </div>
            </div>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import SearchListItem from './component/EventSearchListItem.vue'
import eventRemarksModal from './component/EventRemarksModal.vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'EventStore',
    components: {
        pcgPage,
        errorMsg,
        SearchListItem,
        Carousel,
        Slide,
        Pagination,
        eventRemarksModal
    },
    created: function() {
        this.init()
    },
    data: function() {
        return {
            page: {
                title: '',
                desc: '',
                breadcrumb: [
                    { name: 'イベント', path: '/' },
                    { name: 'イベント検索', path: '/event/search' },
                ],
            },
            activeModal: '',
            error: null,

            shop: null,
            event: null,
            targetMonth: null,
            prevMonth: null,
            nextMonth: null,
            mapUrl: null,
            shopImages: [],

            prevMonthText: '',
            targetMonthText: '',
            nextMonthText: '',

            settings: {
                itemsToShow: 1,
                wrapAround: true,
                snapAlign: "center",
            },
            breakpoints: {
                769: {
                    itemsToShow: 1.27,
                },
            }
        }
    },
    watch: {
        '$route.params': function() {
            const id = this.$route.params.id
            const month = this.$route.params.month
            // 月の切り替えの場合だけinitを呼ぶ。別ページへの遷移では何もしない。
            if (id && month) {
                if (this.$route.path === this.$router.resolve({ name: "EventStoreMonth", params: {'id': id, 'month': month} }).href) {
                    this.init()
                }
            }
        },
    },
    computed: {
        isOrganizer() {
            if (this.shop) {
                let accountType = this.shop.term;
                return accountType == 2 // 1 = shop, 2 = organizer
            }
            return false
        },
        displayTargetMonth() {
            return this.targetMonth ? this.targetMonth.getMonth() + 1 : ''
        },
        displayPrevMonth() {
            return this.prevMonth ? this.prevMonth.getMonth() + 1 : ''
        },
        displayNextMonth() {
            return this.nextMonth ? this.nextMonth.getMonth() + 1 : ''
        },
    },
    methods: {
        init() {
            this.initMonthText()
            this.targetMonth = new Date(new Date().setDate(1))
            let parameterMonth = this.$route.params.month
            if (parameterMonth != undefined) {
                let year = parameterMonth.substr(0, 4)
                let month = parameterMonth.substr(4, 2)
                let parameterYearMonth = new Date(year, month - 1)
                let lowerLimitYearMonth = new Date(this.targetMonth)
                let upperLimitYearMonth = new Date(this.targetMonth)
                lowerLimitYearMonth.setMonth(this.targetMonth.getMonth() - 1)
                upperLimitYearMonth.setMonth(this.targetMonth.getMonth() + 2)
                if (lowerLimitYearMonth > parameterYearMonth || upperLimitYearMonth < parameterYearMonth) {
                    this.$router.push({name: 'NotFound'})
                }
                this.targetMonth.setFullYear(year)
                // Date()に合わせてMonthを"実際の月-1"でセットする
                this.targetMonth.setMonth(month - 1)
            }
            //月初に変更して設定(月末に月加算すると翌々月になる可能性があるため)
            let beginningOfTheMonth = new Date(this.targetMonth.setDate(1));
            this.prevMonth = new Date(
                beginningOfTheMonth.getFullYear(),
                beginningOfTheMonth.getMonth() - 1,
                beginningOfTheMonth.getDate()
            )
            this.nextMonth = new Date(
                beginningOfTheMonth.getFullYear(),
                beginningOfTheMonth.getMonth() + 1,
                beginningOfTheMonth.getDate()
            )
            this.targetMonthText = this.setMonth(this.targetMonth)
            this.prevMonthText = this.setMonth(this.prevMonth)
            this.nextMonthText = this.setMonth(this.nextMonth)
            this.shopImages = []

            let that = this
            let params = new URLSearchParams()
            params.append('shop_id', this.$route.params.id)
            params.append('targetMonth', this.targetMonthText)

            this.axios.get('/shop?shop_id=' + this.$route.params.id + '&targetMonth=' + this.targetMonthText, params)
                .then(response => {
                    const event = response.data.event
                    const shop = response.data.shop
                    that.event = event
                    that.shop = shop
                    that.page.title = shop.shopName
                    that.page.desc = 'ポケモンカードゲーム プレイヤーズクラブの「' + shop.shopName + '」の詳細はこちら。'

                    for (let i = 1; i < 5; ++i) {
                        const name = `shopImg${i}`
                        if (shop[name] && shop[name] != '') {
                            that.shopImages.push(shop[name])
                        }
                    }
                    if (shop.geocoding) {
                        that.mapUrl = 'https://maps.google.co.jp/maps?output=embed&q=' + encodeURIComponent(shop.geocoding)
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        this.$router.push({name: 'NotFound'})
                    } else {
                        this.error = err.response.data.message
                    }
                })
        },
        initMonthText() {
            this.prevMonthText = ''
            this.targetMonthText = ''
            this.nextMonthText = ''
        },
        setMonth(target) {
            let monthText = ''
            monthText += target.getFullYear()
            monthText += (target.getMonth() + 1 < 10) ? '0' : ''
            monthText += target.getMonth() + 1

            return monthText
        },
        isFutureDay() {
            var now = new Date()
            return Number(this.targetMonthText) <= Number(String(now.getFullYear()) + ('0' + String(now.getMonth() + 1)).slice( -2 ))
        },
        getMapUrl(geocoding) {
            return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(geocoding)
        },
        isFutureMaxDay() {
            var now = new Date()
            return Number(String(now.getFullYear()) + ('0' + String(now.getMonth() + 3)).slice( -2 )) <= Number(this.targetMonthText)
        },
        prevImage() {
            const carouselForPC = this.$refs.carouselForPC
            const carouselForSp = this.$refs.carouselForSp

            carouselForPC.prev();
            carouselForSp.prev();
        },
        nextImage() {
            const carouselForPC = this.$refs.carouselForPC
            const carouselForSp = this.$refs.carouselForSp

            carouselForPC.next();
            carouselForSp.next();
        }

    }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

$link-color: #222;

@mixin arrow {
    &::after {
        @include g.setIconFont('\e909');
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 9px;
        margin: auto;
        width: fit-content;
        height: fit-content;
        font-size: 20px;
        @include g.sp {
            right: 0px;
            font-size: 12px;
        }
    }
}

@mixin arrow-left {
    &::after {
        @include g.setIconFont('\e909');
        transform: scaleX(-1);
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 9px;
        margin: auto;
        width: fit-content;
        height: fit-content;
        font-size: 20px;
        @include g.sp {
            right: 0px;
            font-size: 14px;
        }
    }
}

::v-deep(.remarksModal) {
    justify-content: center;

    @include g.pc {
        margin-bottom: 69px;
    }
    @include g.sp {
        padding-top: 16px;
        padding-bottom: 22px;
    }
}

.eventStore-nav {
    @include g.pc {
        & > .c-title-page {
            margin-bottom: 36px;
            &.addRemarks {
                margin-bottom: 20px;
            }
        }
        & > nav {
            display: none;
        }
    }
    @include g.sp {
        position: fixed;
        left: 0;
        right: 0;
        top: g.$header-height-sp;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 124px;
        width: 100%;
        background-color: g.$color-white;
        background-image: url('@/assets/img/common/bg_top.png');
        background-position: g.$header-height-sp center;
        box-shadow: 0 0px 10px rgba(0, 0, 0, .26);
        & > .c-title-page {
            font-size: 24px;
        }
        & > h1 {
            margin-top: 20px;
            padding: 0 20px;
        }
        & > nav {
            ul {
                display: flex;
                li {
                    flex: 1;
                    &:not(:last-child) {
                        border-right: 1px solid g.$color-tab-gray;
                    }
                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 48px;
                        color: $link-color;
                        font-weight: bold;
                        &::after {
                            @include g.setIconFont('\e900');
                            font-size: 10px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}

.shop-info {
    @include g.pc {
        font-size: 14px;
    }
    p {
        margin-top: 12px;
    }
    h2 {
        margin: 12px 0 20px;
        color: g.$color-black;
    }
    .u-text-kome {
        color: g.$color-black-a060;
    }
    .btn-shop-detail {
        display: flex;
        height: 44px;
        line-height: 44px;
        width: 100%;
        padding: 0 19px;
        margin-top: 20px;
        margin-bottom: 8px;
        &::after {
            @include g.setIconFont('\e905');
            font-size: 12px;

        }
        & > span {
            flex: 1;
        }
    }

    @include g.pc {
        margin-bottom: 36px;
    }
    @include g.sp {
        margin-bottom: 56px;
    }
}

.shop-access {
    p {
        margin: 12px 0;
        color: g.$color-black;
    }
    iframe {
        width: 100%;
        border: 1px solid g.$color-border-gray;
    }
    a {
        font-size: 12px;
        @include g.sp {
            font-size: 16px;
        }
    }
    .map-link {
        text-align: right;
        @include g.sp {
            margin-bottom: 56px;
        }
    }
    .map {
        text-align: right;
        @include g.pc {
            margin-top: 15px;
            a {
                @include g.font-sm;
            }
        }
        @include g.sp {
            margin-top: 20px;
        }
    }
    @include g.pc {
        font-size: 14px;
        & > .c-title-h2 {
            margin-bottom: 6px;
            @include g.font-lg;
            &::before {
                display: none;
            }
        }
    }
}

.organizer-info {
    & > .content-profile {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .organizer-image-outer {
            line-height: 0;

            img {
                width: 250px;
                height: 193px;
                object-fit: contain;
                margin-bottom: 20px;
            }
        }

        @include g.sp {
            .organizer-image-outer {
                width: 100%;
                height: 258px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .c-title-h2 {
            margin-bottom: 20px;
        }
        @include g.sp {
            flex-direction: column-reverse;
            .c-title-h2 {
                margin-top: 32px;
                margin-bottom: 6px;
            }
        }
    }
    & > .c-title-h3 {
        margin-bottom: 20px;
        line-height: 1.5;
    }
    & > .content {
        color: g.$color-black;
        margin-bottom: 42px;
        @include g.sp {
            margin-bottom: 36px;
        }
    }
    & > .content-link {
        p {
            color: g.$color-black-a060;
        }
        @include g.sp {
            margin-bottom: 56px;
            text-align: center;
        }
        & > .link {
            @include g.font-sm;
            margin-bottom: 8px;
            @include g.sp {
                @include g.font-normal;
            }
        }
    }
}

.eventStore {
    margin-bottom: 72px;
    @include g.pc {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        .main {
            width: 670px;
        }
        .info {
            width: 250px;
        }
    }
    @include g.sp {
        position: relative;
    }
}

.shopImage {
    @include g.pc {
        &-pc {
            position: relative;
            margin-bottom: 56px;
            .image-nav {
                width: 65px;
                height: 395px;
                color: g.$color-white;
                background: rgba(g.$color-black, 0.5);
                &.prev-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 0 8px 8px 0;
                    button {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include arrow-left;
                        &:after {
                            left: 22px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                &.next-image {
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 8px 0 0 8px;
                    button {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include arrow;
                        &:after {
                            right: 22px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        &-single {
            display: block;
            width: auto;
            max-height: 395px;
            margin: auto;
        }
    }
    @include g.sp {
        &-sp {
            position: relative;
            margin-bottom: 38px;
            .image-nav {
                width: 37px;
                height: 247px;
                color: g.$color-white;
                background: rgba(g.$color-black, 0.5);
                &.prev-image {
                    position: absolute;
                    top: 195px; // paddingの分ずらす
                    left: 0;
                    button {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include arrow-left;
                        &:after {
                            font-size: 20px;
                            left: 8px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                &.next-image {
                    position: absolute;
                    top: 195px; // paddingの分ずらす
                    right: 0;
                    button {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include arrow;
                        &:after {
                            font-size: 20px;
                            right: 8px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        &-single {
            display: block;
            width: auto;
            max-height: 221px;
            max-width: 100%;
            margin: auto;
        }
    }
}
.change-month {
    margin-top: 20px;
    padding: 0 16px 0 24px;
    background: #f2f2f2;
    height: 48px;
    @include g.sp {
        margin-top: 24px;
    }
    &-display {
        font-size: 18px;
        & > span {
            @include g.font-exlg;
        }
    }
    p {
        font-weight: bold;
    }
    .buttons {
        display: flex;
        position: relative;
        .month {
            @include g.font-sm;
            position: relative;
            display: inline-block;
            width: 56px;
            height: 32px;
            line-height: 28px;
            color: $link-color;
            font-weight: bold;
            background: g.$color-white;
            border: 1px solid g.$color-border-gray;
            border-radius: 4px;
            white-space: nowrap;
            @include g.pc {
                &:hover {
                    color: rgba($link-color, .6);
                    background: rgba(g.$color-white, .6);
                    border: 1px solid rgba(g.$color-border-gray, .6);
                }
            }
            &-pre,
            &-next {
                &::before {
                    @include g.setIconFont('\e909');
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: fit-content;
                    height: fit-content;
                    font-weight: normal;
                    font-size: 10px;
                }
            }
            &-pre {
                padding-left: 18px;
                padding-right: 8px;
                margin-right: 6px;
                text-align: left;
                &::before {
                    left: 6px;
                    transform: scale(-0.8, .8);
                }
            }
            &-next {
                padding-right: 18px;
                padding-left: 8px;
                text-align: right;
                &::before {
                    right: 6px;
                    transform: scale(.8);
                }
            }
        }
        .disabled-month {
            @include g.font-sm;
            position: relative;
            display: inline-block;
            width: 56px;
            height: 32px;
            line-height: 28px;
            color: g.$color-border-gray;
            font-weight: bold;
            background: g.$color-white;
            border: 1px solid g.$color-border-gray;
            border-radius: 4px;
            white-space: nowrap;
            &-pre,
            &-pre {
                padding-left: 18px;
                padding-right: 8px;
                margin-right: 6px;
                text-align: left;
                &::before {
                    left: 6px;
                    transform: scale(-0.8, .8);
                }
            }
        }
    }
}

.warning {
    margin-top: 40px;
    @include g.sp {
        @include g.font-normal;
    }
}

// アンカーリンク用の設定
// ヘッダーと固定メニューに被らないようにpadding/marginで位置調整をする
@include g.sp {
    .eventStore {
        padding-top: 120px;
    }
    #info {
        padding-top: 195px;
        margin-top: -195px;
    }
    #access {
        padding-top: 195px;
        margin-top: -195px;
    }
    #profile {
        padding-top: 195px;
        margin-top: -195px;
    }
    #event {
        padding-top: 195px;
        margin-top: -195px;
    }
}

// イベントリストのCSS上書き
::v-deep .eventListItem {
    @include g.pc {
        &:first-child {
            margin-top: 20px;
        }
        position: relative;
        display: flex;
        padding: g.$margin-large 60px g.$margin-large 24px;
        &::after {
            right: 5px;
        }
        .left {
            padding-right: 24px;
            .eventDate {
                width: 156px;
            }
            .status {
                width: 100%;
            }
        }
        .right {
            padding-left: 28px;
            width: 401px;
        }
    }
    @include g.sp {
        &:first-child {
            margin-top: 25px;
        }
        .right .status-5 {
            display: none;
        }
    }
}
</style>

<style lang="scss">
@use '~@/assets/style/global' as g;

// カルーセルのStyle
.carousel__prev,
.carousel__next {
    display: none !important;
}
.carousel__pagination-button {
    width: 8px;
    height: 8px;
    background-color: g.$color-white;
    border: 1px solid g.$color-black;
    border-radius: 10px;
    &--active {
        background-color: g.$color-black;
    }
    @include g.sp {
        width: 10px;
        height: 10px;
    }
}
.carousel__viewport {
    margin-bottom: 8px;
    @include g.sp {
        margin-bottom: 20px;
    }
}
.carousel {
    .shopImage-carousel {
        & > * {
            user-select: none;
            pointer-events: none;
        }
    }
    .shopImage-carousel-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        & > img {
            width: auto;
            height: 100%;
        }
    }
    @include g.pc {
        .shopImage-carousel {
            width: 526px !important;
            height: 395px;
            margin: 0 3.5px;
        }
        .shopImage-carousel-image {
            border-radius: 8px;
        }
    }
    @include g.sp {
        .shopImage-carousel {
            width: 100%;
            height: 247px;
        }
    }
}
.warning-margin {
    @include g.pc {
        margin-bottom: 2px;
    }
    @include g.sp {
        margin-bottom: 0;
    }
}
</style>
