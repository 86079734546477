<template>
    <template v-if="shopData">
        <div
            v-if="isRegistered"
            class="mygym-item default"
        >
            <div class="name">{{ shopData.shop_name }}</div>
            <div class="address-wrap">
                <div class="address-content">
                    <span class="venue">{{ shopData.pref }}</span>
                    <span class="code">〒{{ shopData.zip }}</span>
                    <span class="address">{{ shopData.address }}</span>
                </div>
            </div>
            <div v-if="isOpenShop" class="open-wrap">
                <div class="button-wrap">
                    <router-link
                        :to="{ name: 'EventStore', params: { id: shopData.shop_id } }"
                        class="c-btn c-btn-primary mygym-shop"
                    ><span>店舗詳細</span></router-link>
                </div>
                <div class="detail-date">
                    <span class="registration">登録日 {{ dayjsFormat(shopData.registered_date.date, 'YYYY/MM/DD')}}</span>
                    <span class="last">最後にイベント参加した日 {{ dayjsFormat(shopData.latest_event_date, 'YYYY/MM/DD')}}</span>
                </div>
                <div v-if="isCancelable" class="cancellation">
                    <button
                        class="cancellation-button"
                        @click="openMyGymCancellationModal()"
                    ><span>マイジムから解除する</span></button>
                </div>
            </div>
            <div v-else class="notOpen-wrap">
                <span class="title">こちらの店舗は現在非公開です</span>
                <div class="notOpen-button-wrap">
                    <span class="c-btn c-btn-primary mygym-shop">
                        <span>店舗詳細</span>
                    </span>
                    <div class="text u-text-kome">
                        <span>非公開になっているマイジムはいつでも再登録することが可能です</span>
                    </div>
                </div>
                <div class="cancellation">
                    <button
                        class="cancellation-button"
                        @click="openMyGymCancellationModal()"
                    ><span>マイジムから解除する</span></button>
                </div>
            </div>
        </div>
        <div
            v-else-if="!isRegistered && isNotAllowed"
            class="mygym-item notAllowed"
        >
            <div class="notAllowed-wrap">
                <span class="icon">
                    <img src="@/assets/img/mypage/attention.png">
                </span>
                <div class="text">
                    再登録可能日は
                    <span class="date">{{ dayjsFormat(shopData.registration_possible_date.date, 'YYYY/MM/DD') }}～</span>
                </div>
            </div>
        </div>
        <div
            v-else
            class="mygym-item default registration"
            @click="openMyGymRegistrationModal()"
        >
            <div class="empty-wrap">
                <span class="icon">
                    <img src="@/assets/img/mypage/plus.png">
                </span>
                <p>タップまたはクリックで<br><span class="bold">新規マイジムを登録</span>しよう</p>
            </div>
        </div>
    </template>

    <pcg-modal
        class="myGymRegistrationModal myGymModal"
        :isActive="activeModal == 'myGymRegistrationModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title">{{ modalTitle[modalDisplay] }}</h2>
            <div v-if="modalDisplay == 'default'" class="mygymModal-header">
                <p class="title">マイジムを登録する</p>
                <p class="u-text-kome">マイジムとして登録できるのは、1年以内にイベント参加経験のある店舗に限ります。</p>
            </div>
        </template>

        <template v-if="modalDisplay == 'default'">
            <div class="mygymModal-content-wrap">
                <div class="mygymModal-content">
                    <div class="mygymModal-content-top">
                        <p>マイジムに登録できる店舗</p>
                        <div class="mygymModal-content-search">
                            <div class="keyword">
                                <input
                                    v-model="keyword"
                                    :disabled="gymList.length == 0"
                                    type="text"
                                    class="c-input c-input-text search"
                                    placeholder="キーワードを入力"
                                    @keydown.enter="searchGym"
                                />
                            </div>
                            <button
                                @click="searchGym"
                                :disabled="gymList.length == 0"
                                class="c-btn c-btn-primary"
                            >
                                検索
                            </button>
                        </div>
                    </div>
                    <div class="mygymModal-content-main">
                        <template v-if="searchGymList.length > 0 && modalError == null">
                            <div
                                v-for="gym in searchGymList"
                                :key="`mygymModal-item${gym.shop_id}`"
                                class="mygymModal-content-item"
                            >
                                <div class="name">
                                    <span>{{ gym.shop_name }}</span>
                                </div>
                                <div class="address">
                                    <div class="address-content">
                                        <span class="venue">{{ gym.pref }}</span>
                                        <span class="code">〒{{ gym.zip }}</span>
                                        <span class="address">{{ gym.address }}</span>
                                    </div>
                                </div>
                                <div class="button">
                                    <button
                                        @click="register(gym)"
                                        class="c-btn c-btn-primary"
                                    >
                                        登録する
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div
                            v-else-if="searchGymList.length == 0 && modalError == null"
                            class="u-mt u-mb"
                        >
                            <p>登録可能な店舗が見つかりません。</p>
                        </div>
                        <div
                            v-else
                            class="u-mt u-mb"
                        >
                            <p>{{ modalError }}</p>
                        </div>
                    </div>
                    <div class="close-button">
                        <button
                            type="button"
                            class="c-btn c-btn-primary-outline"
                            @click="closeModal()"
                        >
                            閉じる
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="modalDisplay == 'register'">
            <div class="u-text-center u-mb">
                <p>このマイジムを登録します。</p>
                <p class="u-mb">よろしいですか？</p>
                <p class="u-text-kome">登録後3ヵ月は解除できません。</p>
                <p class="u-text-kome">登録したジムは最後に参加した日から、1年間参加がない場合や、<br>ジム自体が削除された場合に、自動的に解除されます。</p>
            </div>
            <div class="modal-mygym-data">
                <div class="name">
                    <span>店舗名</span>
                    {{ registerGym.shop_name }}
                </div>
                <div class="address">
                    <span>住所</span>
                    {{ registerGym.address }}
                </div>
            </div>
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    キャンセル
                </button>
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="registerNext()"
                >
                    登録する
                </button>
            </div>
        </template>
        <template v-if="modalDisplay == 'registerComplete'">
            <div class="u-text-center u-mb">
                <p>マイジムを登録しました。</p>
                <p>「マイジム」から確認できます。</p>
            </div>
            <div class="modal-mygym-data registerComplete">
                <p>続けてマイジムを登録する方はこちら</p>
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </div>
        </template>
        <template v-if="modalDisplay == 'error'">
            <div class="u-text-center u-mb">
                <p>{{ modalError }}</p>
            </div>
            <div class="modal-mygym-data registerComplete">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </div>
        </template>
    </pcg-modal>

    <pcg-modal
        class="myGymCancellationModal myGymModal"
        :isActive="activeModal == 'myGymCancellationModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title" v-html="modalTitle[modalDisplay]"></h2>
        </template>

        <template v-if="modalDisplay == 'cancellation'">
            <div class="u-text-center u-mb">
                <p>このマイジムを解除します。<br class="u-display-sp">よろしいですか？</p>
            </div>
            <div class="modal-mygym-data">
                <div class="name">
                    <span>店舗名</span>
                    {{ shopData.shop_name }}
                </div>
                <div class="address">
                    <span>住所</span>
                    {{ shopData.address }}
                </div>
            </div>
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="cancellationNext()"
                >
                    解除する
                </button>
            </div>
        </template>
        <template v-if="modalDisplay == 'cancellationComplete'">
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </div>
        </template>
    </pcg-modal>
</template>

<script>
import * as dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import pcgModal from '@/component/Modal.vue'

export default {
    name: 'MyGymItem',
    props: {
        shopData: {
            type: [Object, null],
            required: true,
        },
        myGymNo: {
            type: Number,
            required: true
        }
    },
    emits: [
        'updateMyGymList',
    ],
    components: {
        pcgModal
    },
    data() {
        return {
            activeModal: null,
            modalDisplay: null,
            modalTitle: {
                default: 'マイジム登録',
                register: 'マイジム登録確認',
                registerComplete: 'マイジム登録完了',
                cancellation: 'マイジム解除確認',
                cancellationComplete: 'マイジムを<br class="u-display-sp">解除しました！',
            },
            gymList: [],
            searchGymList: [],
            modalError: null,
            registerGym: null,
            keyword: null
        }
    },
    watch: {
    },
    computed: {
        isRegistered: {
            get() {
                return this.shopData.shop_id != null
            }
        },
        isNotAllowed: {
            get() {
                return (this.shopData.registered_date && !this.shopData.is_registration)
            }
        },
        isOpenShop: {
            get() {
                return (this.shopData.shop_id != null && this.shopData.shop_status_id < 2)
            }
        },
        isCancelable: {
            get() {
                return dayjs(this.shopData.registration_possible_date.date) < dayjs(new Date())
            }
        }
    },
    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)

            return dayjs(date).format(format)
        },
        replaceDate(date) {
            return date.replaceAll('-', '/')
        },
        openMyGymCancellationModal() {
            this.activeModal = 'myGymCancellationModal'
            this.modalDisplay = 'cancellation'
        },
        openMyGymRegistrationModal: function() {
            const getPossibleMyGymApi = '/get_possible_my_gym'

            this.axios.get(getPossibleMyGymApi)
            .then(response => {
                this.gymList = response.data.possible_shop_list
                this.searchGymList = this.gymList
                this.activeModal = 'myGymRegistrationModal'
                this.modalDisplay = 'default'
            })
            .catch(err => {
                this.modalError = err.response.data.message
                this.activeModal = 'myGymRegistrationModal'
                this.modalDisplay = 'default'
            })
        },
        closeModal: function() {
            this.activeModal = null
            this.modalDiaplay = null
            this.registerGym = null
            this.modalError = null
            this.gymList = []
            this.keyword = null
        },
        searchGym: function() {
            this.searchGymList = this.gymList.filter(item => {
                if (!this.keyword) return true
                if (item.shop_name.indexOf(this.keyword) != -1) return true
                if (item.pref.indexOf(this.keyword) != -1) return true
                if (item.address.indexOf(this.keyword) != -1) return true
            })
        },
        register: function(gym) {
            this.registerGym = gym
            this.modalDisplay = 'register'
        },
        registerNext: function() {
            const postMyGymRegistApi = '/my_gym_regist'
            const params = new URLSearchParams
            params.append('shop_id', this.registerGym.shop_id)
            params.append('my_gym_no', this.myGymNo)

            this.axios.post(postMyGymRegistApi, params)
            .then(() => {
                this.modalDisplay = 'registerComplete'
                this.$emit('updateMyGymList')
            })
            .catch(err => {
                this.modalError = err.response.data.message
                this.modalDisplay = 'error'
            })
        },
        cancellationNext: function() {
            const postMyGymCancelApi = '/my_gym_cancel'
            const params = new URLSearchParams
            params.append('my_gym_id', this.shopData.my_gym_id)

            this.axios.post(postMyGymCancelApi, params)
            .then(() => {
                this.modalDisplay = 'cancellationComplete'
                this.$emit('updateMyGymList')
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use './style';
@use './modal';
</style>
