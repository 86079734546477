<template>
    <div class="c-content c-content-wide-sp">
        <div class="eventResult-table">
            <div class="eventResult-table-title-pc">
                <div class="header-title event">
                    イベント名
                </div>
                <div class="header-title rank">
                    成績
                </div>
                <div class="header-title point">
                    獲得チャンピオン<br>
                    シップポイント
                </div>
                <div class="header-title deck">
                    参加デッキ
                </div>
            </div>
            <div
                v-for="result,index in resultList"
                :key="`event-${index}`"
                class="eventResult-table-row"
            >
                <div class="eventResult-table-eventTitle">
                    <div class="eventResult-table-title-top">
                        <template v-if="result.event_holding_id != NULL && linkEventTypes.includes(result.event_type_id) && result.hidden_flg != 1">
                            <router-link
                                :to="{name: 'EventResult', params: {id: result.event_holding_id }}"
                            >
                                <span class="title">{{ result.event_name }}</span>
                                <span class="date">{{ dayjsFormat(result.event_date.date) }}</span>
                            </router-link>
                        </template>
                        <template v-else>
                            <span class="title">{{ result.event_name }}</span>
                            <span class="date">{{ dayjsFormat(result.event_date.date) }}</span>
                        </template>
                    </div>
                    <div class="eventResult-table-title-bottom">
                        <span class="area">{{ result.prefecture_title }}</span>
                        <template v-if="result.event_type_id === 1">
                            <span class="organizer">
                                株式会社ポケモン
                            </span>
                        </template>
                        <template v-else>
                            <router-link
                                v-if="result.shop_id"
                                :to="`/event/search/${result.shop_id}/list`"
                                class="organizer"
                            >
                                {{ result.shop_name }}
                            </router-link>
                            <span
                                class="organizer"
                                v-else-if="result.shop_name_display"
                            >
                                {{ result.shop_name_display }}
                            </span>
                            <span
                                class="organizer"
                                v-else
                            >
                                {{ result.venue ? result.venue : '' }}
                            </span>
                        </template>
                        <div
                            v-if="result.registered_shop_id"
                            class="myGym"
                        >
                            <button class="myGym-button registerd">
                                マイジム登録済み
                            </button>
                        </div>
                        <div
                            v-else-if="result.shop_id && result.shop_status_id === 1 && myGymRegisterable && isPossible(result.event_date.date)"
                            class="myGym"
                        >
                            <button
                                @click="register(result)"
                                class="myGym-button"
                            >
                                マイジムに登録する
                            </button>
                        </div>
                    </div>
                </div>
                <div class="eventResult-table-rank">
                    <div class="eventResult-table-title">
                        成績
                    </div>
                    <div class="eventResult-table-content">
                        <template v-if="displayParticipation(result)">
                            <span>参加</span>
                        </template>
                        <template v-else>
                            <span :class="rankClass(result.rank, result.event_type_id)">
                                <span class="number">{{ displayRank(result.rank, result.event_type_id) }}</span>
                                {{ displayText(result) }}
                            </span>
                            <span v-if="result.event_type_id != 1 && result.participant > 0">（{{ result.participant }}人中）</span>
                        </template>
                    </div>
                </div>
                <div class="eventResult-table-point">
                    <div class="eventResult-table-title">
                        獲得チャンピオン<br>
                        シップポイント
                    </div>
                    <div class="eventResult-table-content">
                        <span class="point">
                            <template v-if="result.champion_ship_point > 0">
                                <span class="point-number">{{ result.champion_ship_point }}</span>pt
                            </template>
                            <template v-else>
                                <span class="point-number">―</span>
                            </template>
                        </span>
                    </div>
                </div>
                <div class="eventResult-table-deck">
                    <a
                        v-if="result.deck_code"
                        class="deck-link"
                        :href="getDeckLinkURL(result.deck_code)"
                        target="_blank"
                    >デッキを見る</a>
                </div>
            </div>
        </div>
    </div>
    <pcg-modal
        class="myGymRegisterModal myGymModal"
        :isActive="activeModal === 'myGymRegisterModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title">{{ modalTitle[modalDisplay] }}</h2>
        </template>

        <template v-if="modalDisplay == 'register'">
            <div class="u-text-center u-mb">
                <p>このマイジムを登録します。</p>
                <p class="u-mb">よろしいですか？</p>
                <p class="u-text-kome">登録後3ヵ月は再登録できません。</p>
                <p class="u-text-kome">登録したジムは最後に参加した日から、1年間参加がない場合や、<br>ジム自体が削除された場合に、自動的に解除されます。</p>
            </div>
            <div class="modal-mygym-data">
                <div class="name">
                    <span>店舗名</span>
                    {{ registerMyGym.shop_name }}
                </div>
                <div class="address">
                    <span>住所</span>
                    {{ registerMyGym.addr }}
                </div>
            </div>
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    キャンセル
                </button>
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="registerNext(registerMyGym.shop_id)"
                >
                    登録する
                </button>
            </div>
        </template>
        <template v-if="modalDisplay == 'registerComplete'">
            <div class="u-text-center u-mb">
                <p>マイジムを登録しました。</p>
                <p>「マイジム」から確認できます。</p>
            </div>
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </div>
        </template>
        <template v-if="modalDisplay == 'error'">
            <div class="u-text-center u-mb">
                <span>{{ modalMessage }}</span>
            </div>
            <div class="modal-buttons">
                <button
                    type="button"
                    class="c-btn c-btn-primary-outline"
                    @click="closeModal()"
                >
                    閉じる
                </button>
            </div>
        </template>
    </pcg-modal>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import pcgModal from '@/component/Modal.vue'

export default {
    name: 'EventResultTable',
    components: {
        pcgModal,
    },
    props: {
        resultList: {
            type: Array,
            required: true,
        },
        myGymRegisterable: {
            type: Boolean,
            required: true,
        },
        oneYearAgo: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            linkEventTypes: [1, 2, 7], // チャンピオンズリーグ・シティリーグ・シールド戦
            modalMessage: null,
            modalDisplay: null,
            activeModal: null,
            modalTitle: {
                register: 'マイジム登録確認',
                registerComplete: 'マイジム登録完了',
                error: '登録に失敗しました',
            },
            registerMyGym: null,
        }
    },
    methods: {
        dayjsFormat(date) {
            dayjs.locale(ja)
            return dayjs(date).format('YYYY/MM/DD（dd）')
        },
        getDeckLinkURL(deck_code) {
            const base_url = process.env.VUE_APP_TRAINERS_WEB_URL
            return base_url + '/deck/confirm.html/deckID/' + deck_code
        },
        displayRank(rank, eventType) {
            if (eventType === 1) {
                return rank
            }

            const rankNumber = parseInt(rank)
            if (rankNumber === 1 || rankNumber === 2) {
                return rankNumber
            } else if (rankNumber === 3 || rankNumber === 4) {
                return '3～4'
            } else if (rankNumber >= 5 && rankNumber <= 8) {
                return '5～8'
            } else if (rankNumber >= 9 && rankNumber <= 16) {
                return '9～16'
            } else if (rankNumber >= 17 && rankNumber <= 32) {
                return '17～32'
            } else if (rankNumber >= 33 && rankNumber <= 48) {
                return '33～48'
            } else if (rankNumber >= 49 && rankNumber <= 64) {
                return '49'
            } else if (rankNumber >= 65) {
                return '65'
            } else if (!rankNumber) {
                return rank
            }
        },
        rankClass(rank, eventType) {
            const rankNumber = parseInt(rank)
            if (rankNumber < 4) {
                return `rank-${rank}`
            }
            if (rankNumber === 4 && eventType != 1) {
                return `rank-3`
            }
        },
        displayParticipation(result) {
            // rankはStringの形式でくるので===で比較しない
            if (result.event_type_id === 1 && result.rank == 9999) {
                return true
            } else if (result.event_type_id != 1 && result.rank == 999) {
                return true
            } else {
                return false
            }
        },
        displayText(result) {
            // rankが数字以外で来たときは"位（以降）"の表示をしない
            if (parseInt(result.rank)) {
                return result.rank > 48 && result.event_type_id != 1 ?  '位以降' : '位'
            }
        },
        isPossible(date) {
            return this.oneYearAgo.date <= date
        },
        register: function(result) {
            this.registerMyGym = result
            this.modalDisplay = 'register'
            this.activeModal = 'myGymRegisterModal'
        },
        registerNext(shopId) {
            const getMyGymApi = '/get_my_gym'
            const myGymNumbers = [1, 2, 3]
            const registeredMyGymNumbers = []
            let myGymNo = null

            this.axios.get(getMyGymApi)
            .then(response => {
                const gymList = response.data.my_gym_list
                if (gymList.length === 0) {
                    // 何も登録されていない時はmy_gym_no=1
                    myGymNo = 1
                    this.postMyGymRegister(shopId, myGymNo)
                } else {
                    gymList.forEach(gym => {
                        if (gym.shop_id || (!gym.shop_id && dayjs(gym.registration_possible_date.date) > dayjs(new Date()))) {
                            registeredMyGymNumbers.push(gym.my_gym_no)
                        }
                    })
                    const uniqueNumbers = myGymNumbers.filter(num => {
                        return registeredMyGymNumbers.indexOf(num) == -1
                    })
                    if (gymList.length === 3) {
                        if (uniqueNumbers.length === 0) {
                            this.modalMessage = '登録できる枠がありません'
                            this.modalDisplay = 'error'
                            this.activeModal = 'myGymRegisterModal'
                        } else {
                            this.postMyGymRegister(shopId, uniqueNumbers[0])
                        }
                    } else {
                        if (uniqueNumbers.length === 0) {
                            this.postMyGymRegister(shopId, gymList.length === 1 ? 2 : 3)
                        } else {
                            this.postMyGymRegister(shopId, uniqueNumbers[0])
                        }
                    }
                }
            })
            .catch(err => {
                if (err.response.data.code == '404') {
                        // 何も登録されていないのでmyGymNo=1
                        this.postMyGymRegister(shopId, 1)
                } else {
                    this.modalMessage = err.response.data.message
                    this.modalDisplay = 'error'
                    this.activeModal = 'myGymRegisterModal'
                }
            })
        },
        postMyGymRegister(shopId, myGymNo) {
            const postMyGymRegistApi = '/my_gym_regist'
            const params = new URLSearchParams
            params.append('shop_id', shopId)
            params.append('my_gym_no', myGymNo)

            this.axios.post(postMyGymRegistApi, params)
            .then(() => {
                this.modalMessage = '登録完了しました'
                this.modalDisplay = 'registerComplete'
                this.activeModal = 'myGymRegisterModal'
                this.$emit('getEventResults')
            })
            .catch(err => {
                this.modalMessage = err.response.data.message
                this.modalDisplay = 'error'
                this.activeModal = 'myGymRegisterModal'
            })
        },
        closeModal() {
            this.modalMessage = null
            this.modalDisplay = null
            this.activeModal = null
        }
    }
}
</script>

<style lang="scss" scoped>
@use './style.scss';
@use '../MyGym/component/modal.scss';
</style>
