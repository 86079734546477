<template>
    <system-page :page-settings="page">
        <component
            :is="subPage"
            v-model="player"
        ></component>
    </system-page>
</template>

<script>
import ProfileEditInput from '@/views/Mypage/Profile/ProfileEditInput.vue'
import ProfileEditConfirm from '@/views/Mypage/Profile/ProfileEditConfirm.vue'
import SystemPage from '@/component/SystemPage.vue'

export default {
    name: 'ProfileEditPage',
    components: {
        SystemPage,
    },
    data() {
        return {
            error: null,
            // APIから値を取得するまでの間、参照されるプロパティのみ列挙
            player: {
                family_name: null,
                family_name_kana: null,
                first_name: null,
                first_name_kana: null,
                nickname: null,
                mail_address: null,
                birthday: null,
                sex: null,
                postal_code: null,
                address: null,
                phone_number: null,
                mail_notification_flg: null,
                publish_flg: null,
                champion_public_flg: null,
                prefecture: null,
            },
        }
    },
    computed: {
        page() {
            let page =  {
                title: 'ユーザー情報変更',
                desc: '',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                    { name: 'ユーザー情報', path: '/mypage/profile' },
                ],
            }
            if (this.$route.hash === '#confirm') {
                page =  {
                    title: 'ユーザー情報変更内容確認',
                    desc: '',
                    breakedTitle: 'ユーザー情報<br>変更内容確認',
                    breadcrumb: [
                        { name: 'マイページ', path: '/mypage' },
                        { name: 'ユーザー情報', path: '/mypage/profile' },
                        { name: 'ユーザー情報変更', path: '/mypage/profile/edit' },
                    ],
                }
            }

            let player = this.$store.getters.getUser();
            if (!player) {
                return page
            }
            let defaultTitle = page.title;
            page.titleForHeader = player.nickname + "さんの" + defaultTitle
            page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
            return page
        },
        subPage() {
            return this.$route.hash === '#confirm' ? ProfileEditConfirm : ProfileEditInput
        },
    },
    watch: {
        '$route.hash': function() {
            window.scrollTo(0, 0)
        }
    },
    created() {
      this.init()
    },
    methods: {
        init() {
            this.axios.post('/get_player_account')
            .then(response => {
                this.player = response.data.player
                if (this.player.new_phone_number) {
                    this.player.phone_number = this.player.new_phone_number
                }
            })
            .catch(err => {
                if (err.response && (err.response.data.status === 401 || err.response.data.code === 404)) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.error = err.response.data.message
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
</style>
