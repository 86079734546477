export default {
    getNewTerminalId() {
        const ID_LENGTH = 100
        const CHARACTER = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        const newTerminalId = [...Array(ID_LENGTH)].map(() => {
            const randomInt = Math.floor(Math.random() * CHARACTER.length)
            return CHARACTER.charAt(randomInt)
        }).join('')

        return newTerminalId
    },
}
