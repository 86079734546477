<template>
    <div :class="$attrs.class" class="remarks">
        <button
            v-if="shopData.beginnerShopFlg"
            class="remarks-button beginner"
            @click.prevent="openModal('beginner')"
        >
            初心者向けジム
        </button>
        <button
            v-if="shopData.strongShopFlg"
            class="remarks-button strong"
            @click.prevent="openModal('strong')"
        >
            強者が集まるジム
        </button>
        <button
            v-if="shopData.championShopFlg"
            class="remarks-button champion"
            @click.prevent="openModal('champion')"
        >
            チャンピオンのマイジム
        </button>
    </div>
    <pcg-modal
        class="shopRemarksModal"
        :isActive="activeModal === 'shopRemarksModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 v-if="modalType == 'beginner'" class="c-title c-title-page">初心者向けジム</h2>
            <h2 v-if="modalType == 'strong'" class="c-title c-title-page">強者が集まるジム</h2>
        </template>
        <div>
            <div
                v-if="modalType == 'beginner'"
                class="modalRemarks-text"
            >
                <p>初心者向けのジムイベントを定期的に開催しているなど、<strong>初心者におすすめのポケモンカードジム</strong>です。</p>
            </div>
            <div
                v-if="modalType == 'strong'"
                class="modalRemarks-text"
            >
                <p>ジムイベントに多くのプレイヤーが集まる、またはシティリーグ優勝等の実績を持つプレイヤーが一定数マイジム登録をしているなど、<strong>強者が集まりやすいポケモンカードジム</strong>です。</p>
            </div>
        </div>
    </pcg-modal>
    <pcg-modal
        class="championInfoModal"
        :isActive="activeModal === 'shopRemarksChampionModal'"
        :bgImage="true"
        @close="closeModal()"
    >
        <template #modalHeader>
            <h2 class="c-title c-title-page">マイジム登録しているチャンピオン</h2>
        </template>
        <div>
            <div class="championInfoModal-champion">
                <span
                    v-for="(champion, i) in championInfos"
                    :key="`champion${i}`"
                    class="champion"
                >
                    {{ champion.large_event_type }}'{{ champion.event_season.substr(2, 2) }}優勝
                </span>
            </div>
            <div class="championInfoModal-text">
                <p>2018年以降のチャンピオンズリーグ・ポケモンジャパンチャンピオンシップス、および2012年以降のポケモンワールドチャンピオンシップスで<strong>優勝経験があるプレイヤーが、マイジム登録をしているポケモンカードジム</strong>です。</p>
            </div>
        </div>
    </pcg-modal>
</template>

<script>
import pcgModal from "@/component/Modal.vue"

export default {
    props: {
        shopData: {
            require: true,
        },
    },
    components: {
        pcgModal,
    },
    data() {
        return {
            championInfos: [],
            activeModal: null,
            modalType: null,
        }
    },
    watch: {
    },
    methods: {
        openModal(type) {
            this.modalType = type
            if (type !== 'champion') {
                this.activeModal = 'shopRemarksModal'
            } else {
                this.openShopRemarksChampionModal()
            }
        },
        closeModal() {
            this.activeModal = null
            this.modalType = null
        },
        openShopRemarksChampionModal: function() {
            const getChampionGymInfoApi = '/get_champion_gym_info'

            this.axios.get(`${getChampionGymInfoApi}?shop_id=${this.shopData.shopId}`)
            .then(response => {
                this.championInfos = response.data.champion_info_list
                this.activeModal = 'shopRemarksChampionModal'
            })
            .catch(err => {
                this.error = err.response.data.message
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;
@use '~@/component/ChampionInfo/style' as championInfo;

.remarks {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    & > .remarks-button {
        display: inline-flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        gap: 2px;
        padding: 4px;
        font-size: g.$font-size-exsmall;
        outline: 1px solid g.$color-border-gray;
        border-radius: 4px;
        background-color: g.$color-white;
        cursor: pointer;

        @include g.sp {
            font-size: 10px;
        }
        @include g.pc {
            &:hover {
                outline: 2px solid g.$color-border-gray;
            }
        }

        &::before,
        &::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-position: center;
        }
        &::after {
            margin-left: 2px;
            background-image: url('@/assets/img/event/remarks/information1.png');
        }
        &.beginner {
            &::before {
                background-image: url('@/assets/img/event/remarks/beginner.png');
            }
        }
        &.strong {
            &::before {
                background-image: url('@/assets/img/event/remarks/strong.png');
            }
        }
        &.champion {
            &::before {
                width: 14px;
                height: 14px;
                background-image: url('@/assets/img/event/remarks/champion.png');
            }
        }
    }
}

.shopRemarksModal {
    .modalRemarks-champion {
        width: fit-content;
        margin: 0 auto;
        padding-bottom: 18px;

        .champion {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 4px;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 22px;
                height: 22px;
                background-size: contain;
                background-position: center;
            }
            &::before {
                margin-right: 3px;
                background-image: url('@/assets/img/event/remarks/icon.png');
            }
        }
    }
    .modalRemarks-text {
        margin-bottom: 40px;
        padding: 20px;
        border: 1px solid g.$color-border-gray;
        border-radius: 10px;
        background-color: g.$color-white;
    }
}
</style>
