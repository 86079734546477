<template>
    <section class="c-content">
        <error-msg
            v-if="errors !== null"
            class="u-mb-lg"
        >
            <p
                v-for="(error, index) in errors"
                :key="index + ':' + error"
            >
                {{ error }}
            </p>
        </error-msg>
        <div class="u-text-center message">
            <p class="u-text">以下の内容で登録します。よろしいですか？</p>
        </div>
        <form action="post">
            <div class="form u-mb c-table-list">
                <div class="c-table-list-row">
                    <div class="column-left">
                        ニックネーム
                    </div>
                    <div class="column-right">
                        {{ player.nickname }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        お名前
                    </div>
                    <div class="column-right">
                        {{ player.family_name }} {{ player.first_name }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        フリガナ
                    </div>
                    <div class="column-right">
                        {{ player.family_name_kana }} {{ player.first_name_kana }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        生年月日
                    </div>
                    <div class="column-right">
                        {{ dayjsFormat(player.birthday, 'YYYY年MM月DD日') }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        性別
                    </div>
                    <div class="column-right">
                        {{ sexList[player.sex] }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        郵便番号
                    </div>
                    <div class="column-right">
                        {{ player.postal_code }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        都道府県
                    </div>
                    <div class="column-right">
                        {{ player.prefecture }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        住所
                    </div>
                    <div class="column-right">
                        {{ player.address }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        携帯電話番号
                    </div>
                    <div class="column-right">
                        {{ player.phone_number }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        マイページ<br class="u-display-pc">公開/非公開
                    </div>
                    <div class="column-right">
                        {{ player.publish_flg ? '公開' : '非公開' }}
                    </div>
                </div>
                <div v-if="player.champion_flg === 1" class="c-table-list-row">
                    <div class="column-left">
                        チャンピオンマーク<br class="u-display-pc">公開/非公開
                    </div>
                    <div class="column-right">
                        {{ player.champion_public_flg ? '公開' : '非公開' }}
                    </div>
                </div>
                <div class="c-table-list-row">
                    <div class="column-left">
                        プロモーション<br class="u-display-pc">メール
                    </div>
                    <div class="column-right">
                        {{ player.mail_notification_flg ? '受け取る' : '受け取らない' }}
                    </div>
                </div>
            </div>
            <div class="u-text-center button-container">
                <button
                    type="button"
                    class="c-btn c-btn-primary"
                    @click="submit()"
                >
                    登録する
                </button>
                <router-link
                    to="/mypage/profile/edit"
                    class="c-btn c-btn-primary-outline"
                >
                    修正する
                </router-link>
            </div>
        </form>
    </section>
</template>

<script>
import errorMsg from '@/component/ErrorMsg.vue'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

export default {
    name: 'ProfileEditConfirmPage',

    components: {
        errorMsg,
    },

    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },

    data: function() {
        return {
            errors: null,
        }
    },

    computed: {
        player: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },
        prefectureList() {
            return this.$store.getters.getPrefectureList()
        },
        sexList() {
            // TODO: storeのマスタから取得？（store側が未実装）
            return {
                1: '男性',
                2: '女性',
                3: '指定しない',
            }
        },
    },

    methods: {
        dayjsFormat(date, format) {
            dayjs.locale(ja)
            if (date !== null && typeof date === 'object' && 'date' in date) {
                return dayjs(date.date).format(format)
            }
            return format
        },

        submit() {
            const params = new URLSearchParams
            const paramKeys = [
                'nickname',
                'sex',
                'phone_number',
                'publish_flg',
                'champion_public_flg',
                'postal_code',
                'address',
                'mail_notification_flg',
            ]
            for (const key of paramKeys) {
                params.append(key, this.player[key])
            }

            let prefecture_id = null
            for (const [, value] of Object.entries(this.prefectureList)) {
                if (value.title === this.player.prefecture) {
                    prefecture_id = value.id
                    break
                }
            }
            params.append('prefecture_id', prefecture_id)

            this.axios.post('/edit_player', params)
            .then(response => {
                this.$store.commit('refreshUser')
                if (response.data.is_auth_phone_number_changed) {
                    this.$router.push({name: 'SmsIdentify', params: { isMypage: '1' }, query: { phoneNumber : response.data.new_phone_number}})
                } else {
                    this.$router.push('/mypage/profile/edit/complete')
                }
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    this.$store.commit('setLogoutState')
                    this.$router.push({name: 'Login'})
                }
                this.errors = err.response.data.message.split('\\n')
                window.scrollTo(0, 0)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-content {
  @include g.pc {
    width: 760px;
  }
}

.message {
    margin-bottom: 56px;
}
.c-table-list {
    border-top: 1px solid #B3D8EE;
    border-bottom: 1px solid #B3D8EE;
    padding-top: 56px;
    padding-bottom: 56px;

    &::before {
        border: 0;
    }
    &-row {
        &:first-of-type {
            .column-left, .column-right {
                padding-top: 0;
            }
        }
        &:nth-of-type(1) {
            border-top: 0;
        }
        &:not(:last-child) {
            border-bottom: 0;
        }
        .column {
            @include g.pc {
                &-left {
                    box-sizing: content-box;
                    flex: 0 0 220px;
                    padding: 22px 0 22px 30px;
                }
                &-right {
                    box-sizing: content-box;
                    padding: 22px 30px 22px 0;
                }
            }
            @include g.sp {
                &-left {
                    font-size: 14px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0;
                    margin-bottom: 10px;
                }
                &-right {
                    font-size: 16px;
                    min-height: 24px;
                    line-height: 24px;
                    padding: 0;
                    margin-bottom: 32px;
                }
            }
            &-left {
                background-color: g.$color-white;
            }
        }
    }
}

.button-container {
    margin-top: 40px;
    .c-btn {
        margin: 0 auto 15px;
        display: block;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.no-border {
    padding-top: 0;
    border: 0;
}
</style>
