<template>
    <pcg-page :page-settings="page">
        <h1 class="c-title c-title-page">{{ page.title }}</h1>
        <div v-if="error" class="c-content u-mb-exlg">
            <error-msg :message="error"></error-msg>
        </div>
        <event-list-search-form
            v-model:searchParameters="searchParameters"
            v-model:searchParameterLabels="searchParameterLabels"
            v-model:selectSpCurrentPlace="selectSpCurrentPlace"
            v-model:selectSearchTab="selectSearchTab"
            :total="pagination.total"
            :modalTotal="pagination.modalTotal"
            @searchSubmit="updateQuery()"
            @searchSubmitCount="countEvents()"
            @setSearchLabels="setSearchParameterLabels()"
            @clearPosition="clearPosition()"
            @searchEvents="searchEvents()"
        ></event-list-search-form>
        <div class="c-content c-content-wide-sp search u-mb-exlg">
            <div class="searchResult" v-if="0 < pagination.total">
                {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
            </div>
            <div
                v-if="renderData === null"
                class="searchList"
            >
                <div class="status-noapply c-content">
                    <div class="status-text close">
                        <span>検索中です</span>
                    </div>
                </div>
            </div>
            <div
                v-else-if="renderData.length !== 0"
                class="searchList"
            >
                <search-list-item
                    v-for="(eventData, index) in renderData"
                    :key="'list' + index"
                    :eventData="eventData"
                    :isSearchByDistance="searchParameters.distance != ''"
                ></search-list-item>
            </div>
            <div class="searchList" v-else>
                <no-result>
                    対象のイベントがみつかりませんでした。
                </no-result>
            </div>
            <pcg-pager
                class="u-mb"
                :page="pagination"
                @pageFeed="pageFeed"
                v-if="0 < pagination.total"
            ></pcg-pager>
            <div class="searchResult u-text-center" v-if="0 < pagination.total">
                {{ pagination.currentPage }}ページ ／ {{ pagination.totalPage }}ページ中
            </div>
        </div>
    </pcg-page>
</template>

<script>
import pcgPage from '@/component/Page.vue'
import pcgPager from '@/component/Pager.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import noResult from '@/component/NoResult.vue'
import eventListSearchForm from '@/component/EventSearchForm/EventListSearchForm.vue'
import SearchListItem from './component/EventSearchListItem.vue'
import dayjs from 'dayjs'
export default {
    name: 'EventSearchPage',

    components: {
        pcgPage,
        pcgPager,
        errorMsg,
        noResult,
        eventListSearchForm,
        SearchListItem,
    },

    data: function () {
        return {
            page: {
                title: 'イベント検索',
                desc: 'ポケモンカードゲーム プレイヤーズクラブのイベント検索はこちら。',
                breadcrumb: [{ name: 'イベント', path: '/' }],
            },
            pagination: {
                currentPage: 1,
                totalPage: 1,
                total: 0,
                modalTotal: 0, // 検索実行すると何件hitするかを示す値
                perPage: 20,
            },
            renderData: null,
            inflection: require('inflection'),

            // current location
            selectSpCurrentPlace: null,
            selectSearchTab: 'detail',
            latitude: null,
            longitude: null,

            shopName: '',
            error: '',

            searchParameters: {
                shopId: '',
                keyword: '',
                startDate: null,
                endDate: null,
                prefecture: [],
                address: '',
                distance: '',
                currentLat: '',
                currentLng: '',
                eventType: [],
                leagueType: [],
                eventDayOfWeek: '',
                eventOpenTime: '',
                reservation: '',
                offset: 0,
                accepting: '',
                link: '',
                order: 1,
            },

            searchParameterLabels: [],
            eventAttributeLabels: {
                1: 'ルールを教わる',
                2: '初心者歓迎',
                3: '真剣勝負',
                4: 'リモートポケカ',
            },
            eventTypeLabels: {
                1: this.$root.getCommonText('champions_league'),
                2: 'シティリーグ',
                3: 'トレーナーズリーグ',
                4: 'ジムイベント',
                5: 'キャンペーン',
                6: 'オーガナイザーイベント',
                7: this.$root.getCommonText('shield_battle'),
            },
            leagueLabels: {
                1: 'オープン',
                2: 'ジュニア',
                3: 'シニア',
                4: 'マスター',
            },
            includeLeagueEventTypes: [
                '3:1',
                '3:2',
                '3:3',
                '3:7',
            ],
            eventDayOfWeekLabels: {
                0: '全ての曜日',
                1: '平日のみ',
                2: '土日祝',
            },
            eventOpenTimeLabels: {
                0: '全ての時間',
                1: '午前スタート',
                2: '午後スタート',
                3: '18時以降スタート',
            },
            reservationLabels: {
                1: '事前応募あり',
                2: '事前応募なし',
            },
        }
    },

    watch: {
        '$route.query': function() {
            if (this.$route.path !== this.$router.resolve({ name: "EventSearch" }).href) {
                return
            }

            if (Object.keys(this.$route.query).length === 0) {
                this.searchParameters.shopId = ''
                this.searchParameters.keyword = ''
                this.searchParameters.startDate = null
                this.searchParameters.endDate = null
                this.searchParameters.prefecture = []
                this.searchParameters.distance = ''
                this.searchParameters.currentLat = ''
                this.searchParameters.currentLng = ''
                this.searchParameters.eventType = []
                this.searchParameters.leagueType = []
                this.searchParameters.eventDayOfWeek = ''
                this.searchParameters.reservation = ''
                this.searchParameters.eventOpenTime = ''
                this.searchParameters.link = ''
                this.searchParameters.offset = 0
                this.searchParameters.order = 1

                this.clearPosition()
            }

            this.createSearchParameters()
            this.setPrefectureList()
            this.searchEvents()
        },
    },

    created: function () {
        // クエリが無い場合、searchParametersの初期値からクエリを作成・更新
        if (Object.keys(this.$route.query).length === 0) {
            this.setPrefectureList()
            this.updateQuery()

            return
        }

        // クエリがある場合、searchParametersをクエリで上書き
        this.createSearchParameters()

        this.setPrefectureList()

        this.searchEvents()
    },

    methods: {
        setPrefectureList: async function () {
            if (localStorage.getItem('prefectureList') != null && this.$route.query['prefecture'] == null && this.$route.query['link'] == null) {
                this.searchParameters.prefecture = localStorage.getItem('prefectureList').split(',')
            }
        },
        updateQuery: async function () {
            const query = await this.createQueryFromSearchParameters()

            // $routerの変更を検知してsearchEventsがcallされる
            this.$router.replace({ query: query })
        },

        createQueryFromSearchParameters: async function() {
            this.error = ''
            const query = {}
            Object.keys(this.searchParameters).forEach((searchName) => {
                if (
                    this.searchParameters[searchName] === null
                    || this.searchParameters[searchName] === ''
                    || (Array.isArray(this.searchParameters[searchName])  && !this.searchParameters[searchName].length)
                ) {
                    return
                }
                if (searchName === 'prefecture') {
                    // 選択されている地域が1件だけの時は配列にする処理をしないとStringとして扱われてしまう
                    const prefecture = Array.isArray(this.searchParameters[searchName])
                        ? this.searchParameters[searchName]
                        : this.searchParameters[searchName].split(',');
                    query[this.inflection.underscore(searchName)] = prefecture
                } else {
                    query[this.inflection.underscore(searchName)] =
                        (searchName === 'startDate' || searchName === 'endDate')
                            ? this.formatDate(this.searchParameters[searchName])
                            : this.searchParameters[searchName]
                }
            })

            if (this.searchParameters['prefecture'] != null && this.searchParameters['prefecture'].length !== 0) {
                localStorage.setItem('prefectureList', query['prefecture'])
            } else {
                localStorage.removeItem('prefectureList')
            }

            if (
                this.selectSearchTab === 'place'
                && this.searchParameters['address']
                && this.searchParameters['distance'] === null
            ) {
                // distanceがない場合はデフォルトの値を入れて検索する
                const defaultDistance = '1'
                query['distance'] = defaultDistance
            }

            if (this.selectSearchTab === 'place' && this.selectSpCurrentPlace) {
                await this.setPositionParameters()
                if (this.latitude !== null && this.longitude !== null) {
                    query['current_lat'] = this.latitude;
                    query['current_lng'] = this.longitude;
                }
                else {
                    delete query['distance']
                }
            } else if (this.searchParameters['currentLat'] === null
                    || this.searchParameters['currentLat'] === ''
                    || this.searchParameters['currentLng'] === null
                    || this.searchParameters['currentLng'] === ''
            ) {
                delete query['current_lat']
                delete query['current_lng']
            }

            query['offset'] = this.searchParameters.offset

            return query
        },

        createSearchParameters: async function() {
            Object.keys(this.searchParameters).forEach((searchName) => {
                const underscoreSearchName = this.inflection.underscore(searchName)
                if (this.$route.query[underscoreSearchName] != null) {
                    if (
                        (searchName === 'eventType' || searchName === 'leagueType')
                        && typeof this.$route.query[underscoreSearchName] === 'string'
                    ) {
                        this.searchParameters[searchName] = [this.$route.query[underscoreSearchName]]
                    }
                    else if (searchName === 'startDate' || searchName === 'endDate') {
                        this.searchParameters[searchName] = new Date(this.$route.query[underscoreSearchName])
                    }
                    else if (searchName === 'prefecture') {
                        // 選択されている地域が1件だけの時は配列にする処理をしないとStringとして扱われてしまう
                        const prefecture = Array.isArray(this.$route.query[underscoreSearchName])
                            ? this.$route.query[underscoreSearchName]
                            : this.$route.query[underscoreSearchName].split(',')
                        this.searchParameters[searchName] = prefecture
                    }
                    else {
                        this.searchParameters[searchName] = this.$route.query[underscoreSearchName]
                    }
                }
            })
            if (this.searchParameters['address'] && this.searchParameters['distance'] === '') {
                this.searchParameters['distance'] = '1'
                this.updateQuery()
            }
        },

        searchEvents: async function () {
            const params = await this.createQueryFromSearchParameters()

            this.pagination.currentPage =
                isNaN(params['offset'])
                ? 1
                : Math.ceil(params['offset'] / this.pagination.perPage) + 1

            if (!params.offset) {
                params.offset = this.searchParameters.offset
            }

            if (params.event_type && !Array.isArray(params.event_type)) {
                params.event_type = [params.event_type]
            }

            this.axios.get('/event_search', { params })
            .then(response => {
                this.renderData = response.data.event
                this.pagination.total = response.data.eventCount ? response.data.eventCount : 0
                this.pagination.modalTotal = response.data.eventCount ? response.data.eventCount : 0
                this.pagination.totalPage = Math.ceil(response.data.eventCount / this.pagination.perPage)
                if (this.pagination.total === 0) {
                    this.pagination.currentPage = 0;
                }
                if (this.$route.query['shop_id'] && this.renderData.length > 0) {
                    this.shopName = this.renderData[0]['shop_name']
                }
                this.setSearchParameterLabels()
            })
            .catch( ()=> {
                this.renderData = []
                this.pagination.total =0
                this.pagination.modalTotal = 0
                this.pagination.totalPage = 0
                this.pagination.currentPage = 0;
                this.setSearchParameterLabels()
            })
        },

        countEvents: async function() {
            const query = await this.createQueryFromSearchParameters()
            query['isLoadingHidden'] = false
            this.axios.get('/event_search/count', { params: query })
            .then(response => {
                this.pagination.modalTotal = response.data.count ? response.data.count : 0
            })
        },

        setSearchParameterLabels: function () {
            this.searchParameterLabels = []
            if (this.searchParameters.keyword) {
                this.searchParameterLabels.push({
                    name: 'keyword',
                    displayName: this.searchParameters.keyword,
                    value: this.searchParameters.keyword,
                })
            }
            if (this.searchParameters.startDate) {
                this.searchParameterLabels.push({
                    name: 'startDate',
                    displayName: '開始日' + this.formatDate(this.searchParameters.startDate),
                    value: this.searchParameters.startDate,
                })
            }
            if (this.searchParameters.endDate) {
                this.searchParameterLabels.push({
                    name: 'endDate',
                    displayName: '終了日' + this.formatDate(this.searchParameters.endDate),
                    value: this.searchParameters.endDate,
                })
            }
            if (this.searchParameters.prefecture) {
                let prefectureText = ''
                for (let [, prefecture] of Object.entries(
                    this.searchParameters.prefecture
                )) {
                    let prefectureName = this.trimPrefecture(this.$store.getters.getPrefectureName(prefecture))
                    this.searchParameterLabels.push({
                        name: 'prefecture',
                        displayName: prefectureName,
                        value: prefecture,
                    })
                    prefectureText += `${prefectureName} `
                }
                const button = document.querySelector('#selectPrefectureText')
                if (button) {
                    button.innerText = prefectureText.length ? prefectureText : '都道府県から絞り込む'
                }
            }
            if (this.searchParameters.address) {
                this.searchParameterLabels.push({
                    name: 'address',
                    displayName: this.searchParameters.address,
                    value: this.searchParameters.address,
                })
            }
            if (this.searchParameters.distance) {
                this.searchParameterLabels.push({
                    name: 'distance',
                    displayName: this.searchParameters.distance + 'km以内',
                    value: this.searchParameters.distance,
                })
            }
            if (this.searchParameters.eventType) {
                for (let [, eventType] of Object.entries(
                    this.searchParameters.eventType
                )) {
                    let eventTypeList = eventType.split(':')
                    this.searchParameterLabels.push({
                        name: 'eventType',
                        displayName:`${this.eventAttributeLabels[eventTypeList[0]]}:${this.eventTypeLabels[eventTypeList[1]]}`,
                        value: eventType,
                    })
                }
            }
            if (this.searchParameters.leagueType) {
                for (let [, league] of Object.entries(this.searchParameters.leagueType)) {
                    this.searchParameterLabels.push({
                        name: 'leagueType',
                        displayName: this.leagueLabels[league],
                        value: league,
                    })
                }
            }
            if (this.searchParameters.eventDayOfWeek) {
                this.searchParameterLabels.push({
                    name: 'eventDayOfWeek',
                    displayName: this.eventDayOfWeekLabels[this.searchParameters.eventDayOfWeek],
                    value: this.searchParameters.eventDayOfWeek,
                })
            }
            if (this.searchParameters.eventOpenTime) {
                this.searchParameterLabels.push({
                    name: 'eventOpenTime',
                    displayName: this.eventOpenTimeLabels[this.searchParameters.eventOpenTime],
                    value: this.searchParameters.eventOpenTime,
                })
            }
            if (this.searchParameters.reservation) {
                this.searchParameterLabels.push({
                    name: 'reservation',
                    displayName: this.reservationLabels[this.searchParameters.reservation],
                    value: this.searchParameters.reservation,
                })
            }
            if (this.$route.query['shop_id']) {
                if (this.$route.query['is_my_gym']) {
                    this.searchParameterLabels.push({
                        name: 'shopId',
                        displayName: 'マイジム',
                        value: this.$route.query['is_my_gym'],
                    })
                } else {
                    this.searchParameterLabels.push({
                        name: 'shopId',
                        displayName: this.shopName,
                        value: this.$route.query['shop_id'],
                    })
                }
            }
        },

        setPositionParameters: async function () {
            if (!this.selectSpCurrentPlace) {
                return
            }

            this.$store.commit('setIsLoading', true)
            try {
                let position = await this.getCurrentPosition()
                this.latitude = position.coords.latitude
                this.longitude = position.coords.longitude
            } catch (error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        break
                    case error.POSITION_UNAVAILABLE:
                        break
                    case error.TIMEOUT:
                        break
                    default:
                }
                this.latitude = null
                this.longitude = null
                this.error = '現在地取得に失敗しました'
            }
            this.$store.commit('setIsLoading', false)
        },

        getCurrentPosition: function () {
            /*
                This API requires HTTPS
                ref. https://sites.google.com/a/chromium.org/dev/Home/chromium-security/deprecating-powerful-features-on-insecure-origins
            */
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve(position)
                }, reject)
            })
        },

        formatDate: function (datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },

        pageFeed(count) {
            const display = this.$store.state.isSp ? '.u-display-sp' : '.u-display-pc'
            const searchForm = document.querySelector(`.p-searchForm-tag-list${display}`)

            if (searchForm.childElementCount === 0) {
                if (Object.keys(this.$route.query).length) {
                    this.createSearchParameters()
                }

                this.setSearchParameterLabels()
            }

            this.pagination.currentPage = count
            this.searchParameters.offset = (this.pagination.currentPage - 1) * this.pagination.perPage
            this.updateQuery()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },

        clearInputForm: function () {
            this.searchParameters.shopId = "";
            this.searchParameters.keyword = "";
            this.searchParameters.startDate = null;
            this.searchParameters.endDate = null;
            this.searchParameters.prefecture = [];
            this.searchParameters.address = "";
            this.searchParameters.distance = "";
            this.searchParameters.eventType = [];
            this.searchParameters.leagueType = [];
            this.searchParameters.eventDayOfWeek = "";
            this.searchParameters.eventOpenTime = "";
            this.searchParameters.reservation = "";
            this.searchParameters.link = "";

            this.clearPosition()
        },

        trimPrefecture(name) {
            return name.replace(/(都|府|県)$/g, '')
        },

        clearPosition() {
            this.selectSpCurrentPlace = null
            this.longitude = null
            this.latitude = null
        },
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.c-title-page {
    @include g.pc {
        margin-bottom: 36px;
    }
    @include g.sp {
        margin-bottom: 35px;
    }
}

.search {
    padding-bottom: 72px;
    @include g.pc {
        margin-top: -40px;
    }
}

.searchResult {
    height: 40px;
    line-height: 40px;
    @include g.sp {
        display: none;
    }
    &.u-text-center {
        margin-top: 28px;
        @include g.sp {
            display: block;
            margin-top: 24px;
        }
    }
}

.noResult {
    margin-top: 80px;
    @include g.sp {
        margin: 0px 7px 0;
    }
}

.status {
    &-noapply {
        margin-top: 40px;
    }
    &-noapply {
        & > .status-text {
            max-width: 400px;
            padding: 8px;
            margin: auto;
            text-align: center;
            font-weight: bold;
            border: 1px solid;
            border-radius: 4px;
            background: g.$color-white;
            &.close {
                color: g.$color-black-a040;
                border-color: g.$color-black-a040;
            }
        }
    }
}

::v-deep .pagination {
    padding-top: g.$margin-large;
}

@include g.sp {
    .searchList {
        padding: 0 9px;
    }
}
</style>
