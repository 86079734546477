<template>
    <system-page :page-settings="pageWithTitle">
        <div class="c-content">
            <p class="u-text-center top-text">マイジムは最大3個まで登録ができます</p>
            <error-msg class="u-mb-lg" v-if="error !== null">
                <p>{{ error }}</p>
            </error-msg>
            <section class="c-gradientBox u-text-center">
                <h3 class="c-title">マイジムで開催されているイベントを探す</h3>
                <div class="u-flex u-flex-center">
                    <button
                        v-if="shopIdList.length === 0"
                        @click="searchGym"
                        :disabled="true"
                        class="c-btn c-btn-primary"
                    >
                        イベントを探す
                    </button>
                    <router-link
                        v-else
                        :to="{
                            name: 'EventSearch',
                            query: { shop_id: shopIdList, is_my_gym: true },
                        }"
                        :disabled="true"
                        class="c-btn c-btn-primary"
                    >
                        イベントを探す
                    </router-link>
                </div>
            </section>
            <h2 class="c-title c-title-h2">マイジムリスト</h2>
            <div v-if="myGymList != null" class="mygym-items">
                <template v-for="i in 3" :key="`myGym-${i}`">
                    <my-gym-item
                        :shop-data="myGymList[i]"
                        :my-gym-no="myGymList[i].my_gym_no"
                        @updateMyGymList="updateMyGymList"
                    ></my-gym-item>
                </template>
            </div>
            <div class="c-content u-text-small note">
                <ul>
                    <li class="u-text-kome">マイジムは3つまで登録枠があります。</li>
                    <li class="u-text-kome">一度登録したマイジムは、3ヶ月間解除することはできません。</li>
                    <li class="u-text-kome">登録したマイジムを解除したい場合、「マイジムから解除する」リンクより解除ください。<br>「マイジムから解除する」リンクは、マイジム登録の3か月後より、表示されます。</li>
                    <li class="u-text-kome">最終参加日から1年経過するとマイジムは自動的に外れます。</li>
                    <li class="u-text-kome">マイジムを変更する場合、一度マイジム登録を解除してから変更したい店舗を登録するようお願い致します。</li>
                    <li class="u-text-kome">マイジム登録していたポケモンカードジムが削除された場合、マイジムは自動的に外れます。</li>
                </ul>
            </div>
            <div class="u-text-center to-mypage margin">
                <router-link
                    :to="{name: 'Mypage'}"
                    class="c-btn c-btn-primary-outline"
                >
                    マイページへ
                </router-link>
            </div>
        </div>
    </system-page>
    <sp-menu />
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import errorMsg from '@/component/ErrorMsg.vue'
import SpMenu from '../component/SpMenu.vue'
import MyGymItem from './component/MyGymItem.vue'

export default {
    name: 'MyGymPage',
    components: {
        SystemPage,
        errorMsg,
        SpMenu,
        MyGymItem
    },
    emits: [
        'updateMyGymList',
    ],
    data() {
        return {
            page: {
                currentPage: 0,
                totalPage: 0,
                totalCount: 0,
                perPage: 20,
                title: 'マイジム',
                breadcrumb: [
                    { name: 'マイページ', path: '/mypage' },
                ],
            },
            myGymList: null,
            error: null,
            shopIdList: [],
        }
    },
    mounted: function() {
        const list = []
        for (let i = 1; i < 4; i++) {
            const item = []
            item.my_gym_no = i
            list[i] = item
        }
        this.myGymList = list
        this.init()
    },
    watch: {
    },
    computed: {
        pageWithTitle: {
            get() {
                let page = this.page
                let player = this.$store.getters.getUser();
                if (!player) {
                    return page
                }
                let defaultTitle = page.title;
                page.titleForHeader = player.nickname + "さんの" + defaultTitle
                page.desc = "ポケモンカードゲーム プレイヤーズクラブ " + player.nickname + "さんの" + defaultTitle + "。";
                return page
            }
        },
    },
    methods: {
        init() {
            this.updateMyGymList()
        },
        updateMyGymList: function() {
            const getMyGymApi = '/get_my_gym'

            this.axios.get(getMyGymApi)
            .then(response => {
                const gymList = response.data.my_gym_list
                let shopIdList = []

                if (gymList.length) {
                    gymList.forEach((gym) => {
                        this.myGymList[gym.my_gym_no] = gym
                        if (gym.shop_id) {
                            shopIdList.push(gym.shop_id)
                        }
                    })
                }
                this.shopIdList = shopIdList
            })
            .catch(err => {
                if (err.response.data.code != '404') {
                    this.error = err.response.data.message
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.top-text {
    margin-bottom: 24px;
}

.c-gradientBox {
    margin-bottom: 42px;
    @include g.sp {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 48px;
    }

    & > .c-title {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 1.25;
        font-weight: bolder
    }
}

.mygym-items {
    display: flex;
    gap: 24px;
    margin-top: 32px;

    @include g.sp {
        flex-direction: column;
        margin-top: 43px;
    }
}

.note {
    margin: 120px 0 49px;

    @include g.sp {
        margin: 32px 0;
    }
}
</style>
